import React, { ReactNode, useEffect, useState } from "react";

import A from "../A";
// eslint-disable-next-line import/no-cycle
import CreateModal from "./Create";
// eslint-disable-next-line import/no-cycle
import DeleteModal from "./Delete";
import Empty from "../Empty";
import Loading from "../Loading";
import Pagination from "../Pagination";
// eslint-disable-next-line import/no-cycle
import UpdateModal from "./Update";
// eslint-disable-next-line
import qs from "qs";
import request from "../../helpers/request";
// eslint-disable-next-line
import { useLocation } from "react-router-dom";

const PAGE_SIZE = parseInt(process.env.REACT_APP_PAGE_SIZE || "10", 10);
// const PAGE_SIZE = 1;

export const ShowTypes = {
  NONE: "none",
  MODAL: "modal",
  PAGE: "page",
};
export const InputTypes = {
  TEXT: "text",
  NUMBER: "number",
  TEXTAREA: "textarea",
  CONTENT: "content",
  DATETIME: "datetime",
  DATE: "date",
  MEDIA: "media",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  SELECT: "select",
  COLOR: "color",
};

export const AlignTypes = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
};

export const CRUD = ({
  readOptions,
  createOptions,
  updateOptions,
  deleteOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const { search } = useLocation();
  const pageSize = readOptions?.pagination?.pageSize || PAGE_SIZE;

  const queryStringParams = new URLSearchParams(search);

  function fetcher(params) {
    setLoading(true);
    request
      .withHeaders({ "Content-Type": "application/json" })
      .build()
      .get(
        `${readOptions?.api}?${qs.stringify(
          params
          //   {
          //   skipNull: true
          // }
        )}`
      )
      .then((response) => {
        setTotalElements(response.data.meta.pagination.total || 0)
        setDataSet(
          [...response.data.data].map(({ id, attributes }) => ({
            id,
            ...attributes,
          }))
        );
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const newQueryStringParams = new URLSearchParams(search);

    fetcher({
      "pagination[page]": (parseInt(newQueryStringParams.get("page") || "1", 10)),
      "pagination[pageSize]": pageSize,
      sort: "id:desc",
      publicationState: "preview",
      populate: "*",
    });

    // eslint-disable-next-line
  }, [search]);

  const removeItem = (id) => {
    const newDataSet = dataSet.filter((d) => d.id !== id);
    setDataSet([...newDataSet]);
  };

  const addItem = (data) => {
    setDataSet([data, ...dataSet]);
  };

  const updateItem = (id, data) => {
    const newDataSet = dataSet.map((x) =>
      x.id === id ? { ...x, ...data } : x
    );
    setDataSet([...newDataSet]);
  };

  return (
    <div className="mt-2">
      <div className="flex flex-col py-4">
        {
          // eslint-disable-next-line no-nested-ternary
          createOptions?.enable &&
          createOptions?.showType === ShowTypes.MODAL ? (
            <CreateModal options={createOptions} addItemToList={addItem} />
          ) : createOptions?.showType === ShowTypes.PAGE ? (
            <div className="mb-4 flex">
              <A
                href={createOptions?.pageAddress}
                className={createOptions?.button?.className}
              >
                {createOptions?.button?.text}
              </A>
            </div>
          ) : (
            ""
          )
        }

        {readOptions &&
          readOptions.columns &&
          readOptions.columns.length > 0 && (
            <>
              <div className="-my-2 overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden overflow-x-auto rounded-md border-b border-neutral-200 shadow-md">
                    <table className="min-w-full divide-y divide-gray-200 overflow-x-scroll">
                      <thead className="bg-gray-50 dark:bg-neutral-900">
                        <tr>
                          {readOptions.columns.map((column, i) => (
                            <th
                              scope="col"
                              key={i}
                              className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              {column.name}
                            </th>
                          ))}
                          {(deleteOptions?.enable || updateOptions?.enable) && (
                            <th>&nbsp;</th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white dark:bg-neutral-900">
                        {loading && (
                          <tr>
                            <td colSpan={readOptions.columns.length + 1}>
                              <div className="flex items-center justify-center px-4 py-10">
                                <Loading />{" "}
                              </div>
                            </td>
                          </tr>
                        )}
                        {!loading && dataSet && dataSet.length === 0 && (
                          <tr>
                            <td colSpan={readOptions.columns.length + 1}>
                              <div className="flex items-center justify-center px-4 py-10">
                                <Empty />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!loading &&
                          dataSet &&
                          dataSet.length > 0 &&
                          dataSet.map((data, i) => (
                            <tr
                              className="transition-all hover:bg-gray-100 hover:shadow-lg dark:hover:bg-gray-800"
                              key={i}
                            >
                              {readOptions?.columns?.map((column, j) => (
                                <td
                                  className={`whitespace-nowrap px-6 py-4 text-sm text-gray-500 ${column.className}`}
                                  key={j}
                                >
                                  {column.render
                                    ? column.render(data[column.key], data, i)
                                    : data[column.key]}
                                </td>
                              ))}
                              {(deleteOptions?.enable === true ||
                                updateOptions?.enable === true) && (
                                <td>
                                  <div className="flex items-center justify-center px-5">
                                    {deleteOptions?.enable === true && (
                                      <DeleteModal
                                        className="mr-3 text-gray-500"
                                        options={deleteOptions}
                                        id={data.id}
                                        removeItemFromList={removeItem}
                                        replaceName={
                                          data[
                                            deleteOptions?.modal?.replaceNameKey
                                          ]
                                        }
                                      />
                                    )}
                                    {updateOptions?.enable === true &&
                                      (updateOptions?.showType ===
                                      ShowTypes.MODAL ? (
                                        <UpdateModal
                                          className="text-gray-500"
                                          options={updateOptions}
                                          dataSet={data}
                                          id={data.id}
                                          replaceName={
                                            data[
                                              updateOptions?.modal
                                                ?.replaceNameKey
                                            ]
                                          }
                                          updateItemInList={updateItem}
                                        />
                                      ) : (
                                        <A
                                          href={updateOptions?.pageAddress?.replace(
                                            /:id/g,
                                            data.id.toString()
                                          )}
                                          className={`${updateOptions?.button?.className} text-gray-500`}
                                        >
                                          {updateOptions?.button?.text || (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-4 w-4"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                              />
                                            </svg>
                                          )}
                                        </A>
                                      ))}
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {totalElements > pageSize ? (
                <Pagination
                  totalItems={totalElements}
                  currentPage={parseInt(
                    queryStringParams.get("page") || "1",
                    10
                  )}
                  pageSize={pageSize}
                  maxPages={5}
                />
              ) : (
                ""
              )}
            </>
          )}
      </div>
    </div>
  );
};
