import { removeLogin, removeToken } from "../helpers/utils";

import request from "../helpers/request";

function login(values) {
  return request
    // .withHeaders({ "Content-Type": "application/json;utf-8" })
    .withHeaders({ "Content-Type": "multipart/form-data;utf-8" })
    .build()
    .post(`/api/auth/local`, values);
}

function logout() {
    removeLogin()
    removeToken()
}

const authenticationServices = {
  login,
  logout,
};

export default authenticationServices;
