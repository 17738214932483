import { useEffect, useRef, useState } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import request from "../../../../helpers/request";
import toast from "react-hot-toast";

const options = {
  accessibility: {
    enabled: false,
  },
  chart: {
    with: "100%",
    // style: {
    //   fontFamily: "Roboto",
    // },
    // marginRight: 0,
    // marginLeft: 0,
    // spacingRight: 55,
    // spacingLeft: 55,
  },
  title: {
    text: "Income User",
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    align: "left",
    verticalAlign: "top",
  },
  xAxis: {
    type: "datetime",
    // lineColor: "transparent",
    labels: {
      style: {
        fontFamily: "Roboto",
        color: "#33475B",
        fontWeight: 400,
        fontSize: "16px",
        fontStyle: "normal",
        lineHeight: "19px",
      },
    },
    title: {
      text: "",
    },
    // scrollbar: {
    //   enabled: true,
    // },
    // offset: 10,
    // min: 0,
    // max: 1,
  },
  yAxis: [
    {
      name: "A0",
      // min: 0,
      // max: 100,
      //   gridLineWidth: 0,
      title: {
        text: "",
      },
      labels: {
        format: "{text}",
      },
    },
  ],

  plotOptions: {
    area: {
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, Highcharts.getOptions().colors[0]],
          [
            1,
            Highcharts.color(Highcharts.getOptions().colors[0])
              .setOpacity(0)
              .get("rgba"),
          ],
        ],
      },
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },
  series: [],
  // responsive: {
  //   rules: [
  //     {
  //       condition: {
  //         minWidth: 1081,
  //       },
  //       chartOptions: {
  //         xAxis: {
  //           max: 4,
  //         },
  //       },
  //     },
  //     {
  //       condition: {
  //         minWidth: 720,
  //         maxWidth: 1080,
  //       },
  //       chartOptions: {
  //         xAxis: {
  //           max: 3,
  //         },
  //       },
  //     },
  //   ],
  // },
};

// eslint-disable-next-line

const DailyView = () => {
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!loading) {
          setLoading(true);
          chartRef.current.chart.showLoading();
          const { data } = await request
            .withHeaders({ "Content-Type": "application/json" })
            .build()
            .get(`/api/events/view-count-daily`, { populate: "*" });

          if (chartRef.current) {
            chartRef.current.chart.addSeries({
              type: "areaspline",
              // color: "#000000",
              name: "User",
              data: data.map((item) => ({
                y: Number(item.unique_views_count),
                x: dayjs(Number(item.view_date) * 1000).valueOf(),
              })),
            });
            //   chartRef.current.chart.xAxis[0].setCategories(
            //     data.map((x) => dayjs(Number(x.view_date) * 1000).valueOf())
            //   );
          }
        }
      } catch (error) {
        toast.custom(
          <div className="flex max-w-sm items-center space-x-5 rounded-lg bg-white p-4 shadow-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 flex-shrink-0 text-red-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{error.message}</span>
          </div>
        );
      } finally {
        chartRef.current.chart.hideLoading();
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="chartContainer flex w-full flex-col items-center justify-between rounded-md bg-white p-4 dark:bg-neutral-700">
      <div className="block w-full">
        <HighchartsReact
          highcharts={Highcharts}
          allowChartUpdate={true}
          immutable={false}
          updateArgs={[true, true, true]}
          containerProps={{ className: "chartContainer" }}
          ref={chartRef}
          options={options}
        />
      </div>
    </div>
  );
};

export default DailyView;
