import { Switch } from "@headlessui/react";
import React, { useEffect } from "react";
import useLocalStorage from "../../../../hooks/useLocalStorage";

const SwitchTheme = () => {
  const [enabled, setEnabled] = useLocalStorage("ema-dark-theme", false);

  useEffect(() => {
    if (enabled) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [enabled]);

  return (
    <>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? "bg-gray-500" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">Dark Theme</span>
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </>
  );
};

export default SwitchTheme;
