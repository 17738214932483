import React from 'react';

const Empty = props => {
  const {image, description} = props;

  return (
    <div className="flex flex-col items-center justify-center text-gray-400">
      <div>
        {image || (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
              />
            </svg>
          </div>
        )}
      </div>
      {description && description.length > 0 ? (
        <div>{description}</div>
      ) : (
        <div className="font-semibold">No Data</div>
      )}
    </div>
  );
};

export default Empty;
