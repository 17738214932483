import "mapbox-gl/dist/mapbox-gl.css";

import React, { useState } from "react";

import InputField from "../../../../components/InputField";
import { InputTypes } from "../../../../components/CRUD";
import MapWrapper from "../Map/MapWrapper";
import Uploader from "../MediaSelectSelf/Uploder";
import request from "../../../../helpers/request";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const fields = [
  {
    title: "Title",
    name: "title",
    id: "title",
    type: InputTypes.TEXT,
    value: "",
  },
  // {
  //   title: "Banner",
  //   name: "banner",
  //   id: "banner",
  //   type: InputTypes.MEDIA,
  //   value: "",
  // },
  // {
  //   title: "Media",
  //   name: "media",
  //   id: "media",
  //   type: InputTypes.MEDIA,
  //   isMulti: true,
  //   value: [],
  // },
  {
    title: "Content",
    name: "content",
    id: "content",
    type: InputTypes.TEXTAREA,
    value: "",
  },
  {
    title: "Type",
    name: "type",
    id: "type",
    type: InputTypes.SELECT,
    options: [
      { label: "Default", value: "default" },
      { label: "Breaking  News", value: "breaking_news" },
    ],
    value: null,
  },
  {
    title: "Event At",
    name: "eventAt",
    id: "eventAt",
    className: "!mt-24",
    type: InputTypes.DATE,
    value: "",
  },
];

const DraftContentPage = () => {
  const navigate = useNavigate();

  const [location, setLocation] = useState(undefined);
  const [banner, setBanner] = useState(undefined);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState(
    fields.reduce((result, item) => {
      // eslint-disable-next-line no-param-reassign
      result[item.name] = item.value;
      return result;
    }, {})
  );

  const validate = () => {
    if (!values.title) {
      throw new Error("Title is required");
    }
    if (!values.content) {
      throw new Error("Content is required");
    }
    if (!values.type) {
      throw new Error("Type is required");
    }
    if (!values.eventAt) {
      throw new Error("Event At is required");
    }
    if (!banner) {
      throw new Error("Banner is required");
    }
    const { mime } = banner;
    if (banner && !["image/png", "image/gif", "image/jpeg"].includes(mime)) {
      throw new Error("Banner is must be image");
    }
    if (!media || media.length === 0) {
      throw new Error("Media is required");
    }
    if (!location) {
      throw new Error("Location is required");
    }
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await validate();
      await request
        .withHeaders({ "Content-Type": "application/json" })
        .build()
        .post(
          "/api/events",
          JSON.stringify({
            data: {
              ...values,
              banner,
              media,
              location,
              publishedAt: new Date(),
            },
          })
        );

      navigate("/dashboard/contents");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDraft = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await validate();
      await request
        .withHeaders({ "Content-Type": "application/json" })
        .build()
        .post(
          "/api/events",
          JSON.stringify({
            data: { ...values, location, banner, media, publishedAt: null },
          })
        );

      navigate("/dashboard/contents");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if ("eventAt" === e.target.name) {
      const v = dayjs(e.target.value).utcOffset(dayjs().utcOffset());
      setValues({ ...values, [e.target.name]: new Date(v.format('YYYY-MM-DD')) });
    } else setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="mb-5 flex items-center justify-between">
        <h2 className="text-4xl font-black">Create New Content</h2>

        <div className="flex items-center space-x-2 ">
          <button
            type="button"
            className="rounded bg-black px-8 py-2 text-white"
            disabled={loading}
            onClick={handlePublish}
          >
            Publish
          </button>

          <button
            type="button"
            className="rounded bg-black/50 px-8 py-2 text-white"
            disabled={loading}
            onClick={handleDraft}
          >
            Suspend
          </button>
        </div>
      </div>

      {/* <div className="flex flex-col space-x-0 xl:flex-row xl:space-x-8"> */}
      <div className="flex flex-col">
        {/* <div className="order-2 mb-8 flex-grow self-start rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white xl:order-1"> */}
        <div className="mb-8 w-full flex-grow rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
          <div className="space-y-6">
            {fields
              ? fields.map((field, i) => (
                  <InputField
                    field={field}
                    key={i}
                    value={values[field.name]}
                    handleChange={handleChange}
                  />
                ))
              : ""}
          </div>
        </div>
        {/* <div className="order-1 mb-8 space-y-8 self-stretch xl:order-2 xl:w-2/6 xl:self-start"> */}
        <div className="mb-8 w-full space-y-8 self-stretch">
          <div className="flex flex-col space-y-2 rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
            <span className="font-semibold text-gray-600 dark:text-neutral-500">
              Banner
            </span>
            <div className="">
              <Uploader
                uploadedFile={banner}
                setUploadedFiles={(file) => setBanner(file)}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
            <span className="font-semibold text-gray-600 dark:text-neutral-500">
              Media
            </span>
            <Uploader
              isMulti={true}
              uploadedFiles={media}
              setUploadedFiles={(files) => setMedia(files)}
            />
          </div>
          <div className="flex flex-col space-y-2 rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
            <span className="font-semibold text-gray-600 dark:text-neutral-500">
              Map
            </span>
            <MapWrapper setLocation={setLocation} location={location} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftContentPage;
