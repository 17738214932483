import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { addFileToFiles, selectFiles, uploadFilesAsync } from '../../MediaSelect/filesSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import Empty from '../../Empty';

function getBase64(file, cb) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}


const ImagePreview = ({ file, className }) => {
  const imgRef = useRef();

  useEffect(() => {
    getBase64(file, (result) => {
      imgRef.current.src = result;
    });
    //  eslint-disable-next-line
  }, []);

  return <img src="" ref={imgRef} className={className} alt={file.name} />;
};




const AddMediaButton = ({ className }) => {
  let dragCounter = 0;

  const { uploadStatus: status, uploadErrors: errors, uploadFiles } = useAppSelector(selectFiles);
  const dispatch = useAppDispatch();

  const inpRef = useRef();
  const dropZoneRef = useRef();

  const [modal, setModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (!modal) {
      setFiles([])
    }
  }, [modal])

  const handleCancel = () => {
    setModal(false);
  };

  const handleOk = () => {
    setSubmitted(true);
    dispatch(uploadFilesAsync(files));
  };

  useEffect(() => {
    if (status === "idle" && submitted) {
      setSubmitted(false);
      if (!errors.length) {
        dispatch(addFileToFiles(uploadFiles))
        setModal(false);
      }
    }
  }, [status]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-plusplus
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-plusplus
    dragCounter--;
    if (dragCounter > 0) return;
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFiles([...files, ...e.dataTransfer.files]);
      // e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  const handleChange = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  return (
    <>
      <button
        type="button"
        className={
          className
            ? className
            : 'rounded-md px-2 py-1 bg-black hover:bg-black text-white text-sm'
        }
        onClick={() => setModal(!modal)}
      >
        Add new File
      </button>


      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={handleCancel}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="all"
              enterTo="all"
              leave="ease-in duration-200"
              leaveFrom="all"
              leaveTo="all"
            >
              <Dialog.Overlay className="fixed inset-0 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-90" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-block w-full max-w-lg p-10 my-8 overflow-hidden align-middle transition-all transform bg-white shadow-2xl rounded-2xl">
                <Dialog.Title as="h3" className="font-bold leading-6 text-gray-900 my-8">
                  Add new File
                </Dialog.Title>

                <button
                  type="button"
                  className="absolute top-3 left-4 text-gray-300 cursor-pointer"
                  onClick={handleCancel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>



                <div>
                  <input
                    ref={inpRef}
                    type="file"
                    name="files"
                    className="hidden"
                    id="files"
                    multiple
                    onChange={handleChange}
                  />
                  <div
                    ref={dropZoneRef}
                    onDragEnter={handleDragIn}
                    onDragExit={handleDragOut}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    className={`m-h-20 w-full py-10 rounded-md border-2 border-dashed flex flex-col items-center justify-center ${dragging ? 'border-black' : ''
                      }`}
                  >
                    <span className="mb-5"> Drag and drop your files anywhere or </span>
                    <button
                      type="button"
                      onClick={() => inpRef.current?.click()}
                      className="text-sm px-4 py-1 bg-gray-200 rounded-md"
                    >
                      Choose File(s)
                    </button>
                  </div>

                  <div className="flex flex-col mt-8">
                    {files && files.length > 0 ? (
                      <>
                        <span className="mt-8">Files:</span>
                        <div className="flex flex-col">
                          {files.map(file => (
                            <div
                              className="flex items-center justify-between py-3 px-3 bg-gray-100 rounded-md mb-2"
                              key={file.lastModified}
                            >
                              <div className="flex items-center truncate">
                                <ImagePreview
                                  file={file}
                                  className="flex-shrink-0 w-10 h-10 mr-2 rounded-md overflow-hidden"
                                />
                                <span className="truncate">{file.name}</span>
                              </div>
                              <div className="flex items-center justify-between">
                                <button
                                  type="button"
                                  onClick={() =>
                                    setFiles(
                                      files.filter(
                                        f => f.lastModified !== file.lastModified,
                                      ),
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <Empty />
                    )}
                  </div>
                </div>



                <div className="flex flex-col justify-center items-center mt-10">
                  <div className="mb-6 flex justify-center space-x-2 rtl:space-x-reverse">
                    <button
                      type="button"
                      disabled={submitted}
                      onClick={handleOk}
                      className={`flex items-center justify-center bg-gray-900 shadow-xl rounded px-12 text-sm py-2 text-white ${submitted ? "bg-opacity-70" : ""}`}
                    >
                      <span>{files.length > 0 ? `Upload ${files.length} file` : 'Upload file(s)'}</span>
                    </button>

                    <button
                      type="button"
                      onClick={handleCancel}
                      className="flex items-center justify-center rounded px-12 text-sm py-2 bg-white border border-gray-900 text-gray-900"
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}



export default AddMediaButton