import dayjs from "dayjs";

let axiosRequestConfig = {
  headers: {
    Accept: "application/json;utf-8",
  },
};

export const setRequestConfig = (config) => {
  let newHeader = config.headers;
  if (newHeader) {
    newHeader = {
      ...axiosRequestConfig.headers,
      ...config.headers,
    };
  }
  axiosRequestConfig = { ...axiosRequestConfig, ...config };

  return { ...axiosRequestConfig, ...config };
};

export const setMediaTypeConfig = (config) => {
  switch (config) {
    // case EHEADER.HEADER_JSON:
    //   setRequestConfig({
    //     headers: { "Content-Type": "application/json;utf-8" },
    //   });
    //   break;
    // case EHEADER.HEADER_MULTIPART:
    //   setRequestConfig({
    //     headers: { "Content-Type": "multipart/form-data;utf-8" },
    //   });
    //   break;
    // case EHEADER.HEADER_URL_ENCODE:
    //   setRequestConfig({
    //     headers: { "Content-Type": "application/x-www-form-urlencoded;utf-8" },
    //   });
    //   break;
    default:
      setRequestConfig({
        headers: { "Content-Type": "application/json;utf-8" },
      });
      break;
  }
};

export function isLogin() {
  // const profileStr = localStorage.getItem('ema-userinfo');
  const tokenStr = localStorage.getItem("ema-token");
  if (tokenStr && tokenStr.length > 0) {
    return true;
  }
  return false;
}

export const setLogin = (param) => {
  localStorage.setItem("ema-userinfo", JSON.stringify(param));
};

export const getLogin = () => {
  const profileStr = localStorage.getItem("ema-userinfo");
  return JSON.parse(profileStr || "{}");
};

export const getToken = () => {
  const token = localStorage.getItem("ema-token");

  return token;
};

export const setToken = (token) => {
  localStorage.setItem("ema-token", token);
};

export const removeLogin = () => {
  localStorage.removeItem("ema-userinfo");
};

export const removeToken = () => {
  localStorage.removeItem("ema-token");
};

export const onPress = (event) => {
  let mainKey = event.key;
  const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  if (persianNumbers.includes(mainKey)) {
    mainKey = persianNumbers.indexOf(mainKey).toString();
  }
  if (!keys.includes(mainKey)) {
    event.preventDefault();
  } else {
    // const englishNumber = persianNumbers.includes(mainKey)
    //   ? persianNumbers.indexOf(mainKey)
    //   : mainKey;
    return mainKey;
  }
  return false;
};

export const fixNumbers = (e) => {
  const persianNumbers = [
    /۰/g,
    /۱/g,
    /۲/g,
    /۳/g,
    /۴/g,
    /۵/g,
    /۶/g,
    /۷/g,
    /۸/g,
    /۹/g,
  ];
  const arabicNumbers = [
    /٠/g,
    /١/g,
    /٢/g,
    /٣/g,
    /٤/g,
    /٥/g,
    /٦/g,
    /٧/g,
    /٨/g,
    /٩/g,
  ];
  let str = e.value;
  if (typeof str === "string") {
    for (let i = 0; i < 10; i += 1) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }

  return str;
};

export const toPersianDigit = (str) => {
  const id = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  return str.replace(/[0-9]/g, (w) => id[+w]);
};

export const getBgColorGradientByStatus = (status) => {
  let bg = "linear-gradient(217deg, #B2B2B2 0%, #D5D5D5 100%)";
  if (status === "DISQUALIFIED") {
    bg = "linear-gradient(217deg, #8F0001 0%,  #CB0002 100%)";
  } else if (status === "CONDITIONAL_QUALIFIED") {
    bg = "linear-gradient(217deg, #E96F04 0%, #FF983E 100%)";
  } else if (status === "QUALIFIED") {
    bg = "linear-gradient(217deg, #039572 0%, #49C9AB 100%)";
  }

  return bg;
};

export const getCookieLanguages = () => {
  var value = "; " + document.cookie;
  var parts = value.split("; i18next=");
  if (parts.length === 2) return parts.pop().split(";").shift();
  return "fa";
};

export const getCookieCsrf = () => {
  var value = "; " + document.cookie;
  var parts = value.split("; X-CSRF-TOKEN=");
  if (parts.length === 2) return parts.pop().split(";").shift();
  return "";
};

export const isRtlLanguages = () => {
  const rtlLanguages = ["fa", "ar"];
  return rtlLanguages.includes(getCookieLanguages());
};

export const convertBase64ToArrayBuffer = (base64) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const downloadBlob = (blob, name) => {
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};

async function url2blob(url) {
  try {
    const data = await fetch(url);
    const blob = await data.blob();
    return blob;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

export const detectFileTypeFromUrl = (file) => {
  const fileReader = new FileReader();
  return new Promise(async (resolve, reject) => {
    try {
      fileReader.onloadend = async function (e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
          if (i + 1 === arr.length) {
            switch (header) {
              case "89504e47":
                resolve("image/png");
                break;
              case "47494638":
                resolve("image/gif");
                break;
              case "ffd8ffe0":
              case "ffd8ffe1":
              case "ffd8ffe2":
              case "ffd8ffe3":
              case "ffd8ffe8":
                resolve("image/jpeg");
                break;
              case "dfbf34eb":
              case "25504446":
                resolve("application/pdf");
                break;
              case "504b34":
                resolve("application/zip");
                break;
              case "504b0304":
                resolve("application/msword");
                break;
              case "d0cf11e0":
                resolve(
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                );
                break;
              default: // Or you can use the blob.type as fallback
                resolve("unknown");
                break;
            }
          }
        }
      };
      const a = await url2blob(file);
      fileReader.readAsArrayBuffer(a);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export function getUrlExtension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export const convertGregorianDateToObjectDate = (date, calendar) => {
  if (!date) return null;
  const d = dayjs(new Date(date));

  console.log(d);

  return {
    year: Number(d.calendar(calendar || "jalali").format("YYYY")),
    month: Number(d.calendar(calendar || "jalali").format("MM")),
    day: Number(d.calendar(calendar || "jalali").format("DD")),
  };
};

export const isEmpty = (item) => {
  for (let i in item) {
    return false;
  }
  return true;
};

export const removeDuplicateItems = (items, fn) => {
  if (items.length === 0 || items.length === 1) {
    return items;
  }
  for (let i = 0; i < items.length; i++) {
    for (let j = i + 1; j < items.length; j++) {
      if (fn(items[i], items[j])) {
        items.splice(i, 1);
      }
    }
  }
  return items;
};

export const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      let result;
      result = reader.result;
      resolve(result.split(",")[1]);
    };
    reader.onerror = (error) => reject(error);
  });

export const isEmptyString = (str) => {
  return !str || str.length === 0;
};
