import React, { useEffect, useState } from 'react';
import { fetchFilesAsync, selectFiles } from '../../../MediaSelect/filesSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import AddMediaButton from '../../../Media/AddMediaButton';
import Empty from '../../../Empty';
import { v4 as uuid } from 'uuid';

const MediaSelect = ({ value, multi, field, handleClick: handleClickProps }) => {
  const { files } = useAppSelector(selectFiles);
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState(value);

  const useEffectOnMount = (effect) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  useEffectOnMount(() => {
    dispatch(fetchFilesAsync());
  });

  const handleClick = (e) => {
    e.preventDefault();
    if (e.target.checked) {
      if (multi && Array.isArray(selected)) {
        setSelected([...selected, e.target.value]);
      } else setSelected(e.target.value);
    } else if (Array.isArray(selected)) {
      setSelected([...selected.filter(s => s !== e.target.value)]);
    }
  };

  useEffect(() => {
    handleClickProps({ target: { value: selected } });
    //  eslint-disable-next-line
  }, [selected]);

  return (
    <div className="p-3 pt-5 mb-3 border-dashed border-2 relative">
      <AddMediaButton className="rounded-md px-2 py-1 bg-black hover:bg-black text-white text-sm absolute -top-3.5 right-2.5" />

      {files && files.length > 0 ? (
        <div
          className="grid gap-1"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
          }}
        >
          {files.map(file => {
            const checked =
              // eslint-disable-next-line no-nested-ternary
              selected?.length > 0
                ? multi && Array.isArray(selected)
                  ? selected
                    .map(i => i.toString())
                    .includes(file.url)
                  : selected === file.url
                : false;

            const defaultId = uuid();

            return (
              <div
                className="bg-gray-100 w-24 p-2 flex flex-col items-start rounded-md"
                key={defaultId}
              >
                <input
                  type={multi ? 'checkbox' : 'radio'}
                  className="mb-2"
                  value={file.url}
                  name={field.name}
                  id={field.name + file.id}
                  onChange={handleClick}
                  checked={checked}
                />
                <label htmlFor={field.name + file.id}>
                  <img
                    src={`${process.env.REACT_APP_PICTURE_BASE_API_URL}${file.formats?.thumbnail?.url || file.url
                      }`}
                    className="w-20 h-20 self-center"
                    alt={field.name + file.id}
                  />
                </label>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center items-center w-full">
          <Empty />
        </div>
      )}
    </div>
  );
}

export default React.memo(MediaSelect)
