import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components";

const Icon = styled.span``;
const Select = ({ options, value, name, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(
    options?.find((v) => v.value.toString() === value?.toString())
  );

  useEffect(() => {
    setSelectedOption(
      options?.find((v) => v.value.toString() === value?.toString())
    );
  }, [value]);

  return (
    <Listbox
      value={selectedOption}
      onChange={(e) => onChange({ target: { name, value: e.value } })}
    >
      <div className="relative">
        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-200 bg-white py-2 pl-3 pr-10 text-left text-xs text-gray-400 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 dark:border-neutral-200 dark:bg-neutral-700 dark:text-neutral-400">
          <span className="truncate">
            {selectedOption ? selectedOption.label : "Choose Item"}
          </span>
          <Icon className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </Icon>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900">
            {options
              ? options.map((option, i) => (
                  <Listbox.Option
                    key={i}
                    value={option}
                    className={({ active }) =>
                      `${
                        active
                          ? "bg-gray-100 text-gray-900 dark:bg-neutral-600 dark:text-neutral-400"
                          : "text-gray-900 dark:text-neutral-500"
                      } relative cursor-default select-none py-2 pl-10 pr-4`
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate`}
                        >
                          {option.label}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? "text-gray-600" : "text-gray-600"
                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))
              : ""}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Select;
