import React, { memo, useEffect, useState } from "react";
import { fetchFilesAsync, selectFiles } from "./filesSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import AddMediaButton from "../Media/AddMediaButton";
import Empty from "../Empty";
import { v4 as uuid } from "uuid";

const MediaSelect = ({
  value,
  field,
  multi,
  handleClick: handleClickProps,
}) => {
  const { files } = useAppSelector(selectFiles);
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState(value);

  const useEffectOnMount = (effect) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  useEffectOnMount(() => {
    dispatch(fetchFilesAsync());
  });

  const handleClick = (e) => {
    e.preventDefault();
    if (e.target.checked) {
      if (multi && Array.isArray(selected)) {
        setSelected([...selected, e.target.value]);
      } else setSelected(e.target.value);
    } else {
      // if (Array.isArray(selected)) {
      //   setSelected([...selected.filter(s => s !== e.target.value)]);
      // }
    }
  };

  useEffect(() => {
    handleClickProps({ target: { value: selected, name: field.name } });
    //  eslint-disable-next-line
  }, [selected]);

  return (
    <div className="relative mb-3 border border-dashed p-3 pt-5">
      <AddMediaButton className="absolute -top-3.5 right-2.5 rounded-md bg-black px-2 py-1 text-sm text-white hover:bg-black" />

      {files && files.length > 0 ? (
        <div
          className="grid gap-1"
          style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
          }}
        >
          {files.map((file) => {
            const checked =
              // eslint-disable-next-line no-nested-ternary
              value?.length > 0
                ? multi && Array.isArray(value)
                  ? value
                      .map((i) => i && i.toString())
                      .includes(file.id.toString())
                  : value.toString() === file.id.toString()
                : false;

            const defaultId = uuid();

            return (
              <div
                className="flex w-24 flex-col items-start rounded-md bg-gray-100 p-2 dark:bg-neutral-800"
                key={defaultId}
              >
                <input
                  type={multi ? "checkbox" : "radio"}
                  className="mb-2"
                  value={file.id}
                  name={field.name}
                  id={field.name + file.id}
                  onChange={handleClick}
                  checked={checked}
                />
                <label htmlFor={field.name + file.id}>
                  <img
                    src={`${process.env.REACT_APP_PICTURE_BASE_API_URL}${
                      file.formats?.thumbnail?.url || file.url
                    }`}
                    className="h-20 w-20 self-center"
                    alt={field.name + file.id}
                  />
                </label>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex w-full items-center justify-center">
          <Empty />
        </div>
      )}
    </div>
  );
};

export default memo(MediaSelect);
