import React, { useEffect, useState } from "react";

import request from "../../../../helpers/request";
import toast from "react-hot-toast";

const PopularEvent = () => {
  // eslint-disable-next-line
  const [event, setEvent] = useState(undefined);

  useEffect(() => {
    request
      .withHeaders({ "Content-Type": "application/json" })
      .build()
      .get(`/api/events/popular-event`, { populate: "*" })
      .then((response) => {
        setEvent(response.data);
      })
      .catch((error) =>
        toast.custom(
          <div className="flex max-w-sm items-center space-x-5 rounded-lg bg-white p-4 shadow-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 flex-shrink-0 text-red-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{error.message}</span>
          </div>
        )
      );
  }, []);

  return (
    <div className="flex items-center justify-between rounded-md bg-white p-4 dark:bg-neutral-700">
      <div>
        <h6 className="dark:text-primary-light text-xs font-medium uppercase leading-none tracking-wider text-gray-500">
          Popular Event
        </h6>
        <a
          href={`${process.env.REACT_APP_CLIENT_BASE_URL}/news/${
            event?.id
          }/${event?.title.replaceAll(/\s/gm, "_")}`}
        >
          <span className="text-xl font-semibold">{event?.title}</span>
        </a>
      </div>
      <div>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-gray-300 dark:text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default PopularEvent;
