import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment, useState} from 'react';

import request from '../../../helpers/request';

const DeleteModal = ({
  options,
  id,
  className,
  replaceName,
  removeItemFromList,
}) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const handleOk = (e) => {
    e.preventDefault();
    setLoading(true);
    request
      
      .withHeaders({'Content-Type': options.contentType || 'application/json'})
      .build()
      .delete(options.api.replace(/:id/g, id.toString()))
      .then(
        // eslint-disable-next-line
        res => {
          if (removeItemFromList) removeItemFromList(id);
          setModal(false);
        }
      )
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setModal(false);
  };

  return (
    <>
      <button type="button" className={className} onClick={() => setModal(!modal)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </button>

      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={handleCancel}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="all"
              enterTo="all"
              leave="ease-in duration-200"
              leaveFrom="all"
              leaveTo="all"
            >
              <Dialog.Overlay className="fixed inset-0 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-90" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-block w-full max-w-lg px-10 py-8 my-8 overflow-hidden align-middle transition-all transform bg-white shadow-2xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="font-bold text-2xl leading-6 text-gray-900 mb-8 text-left"
                >
                  {options?.modal?.title?.replace(/%s/g, replaceName)}
                </Dialog.Title>

                <button
                  type="button"
                  className="absolute top-8 right-10 text-gray-300 cursor-pointer"
                  onClick={handleCancel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <div className="mb-10">Do you want to delete &apos;{replaceName}&apos;?</div>

                <div className="flex flex-col justify-center items-center mt-10">
                  <div className="mb-3 flex justify-center space-x-2 rtl:space-x-reverse">
                    <button
                      type="button"
                      disabled={loading}
                      onClick={handleOk}
                      className="flex items-center justify-center bg-black shadow-xl rounded px-12 text-sm py-2 text-white"
                    >
                      <span>{options?.modal?.okText || 'Save and Publish'}</span>
                    </button>

                    <button
                      type="button"
                      onClick={handleCancel}
                      className="flex items-center justify-center rounded px-12 text-sm py-2 bg-white border border-gray-900 text-gray-900"
                    >
                      <span>{options?.modal?.cancelText || 'Cancel'}</span>
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DeleteModal;
