import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import Empty from "../../../../components/Empty";
import Loading from "../../../../components/Loading";
import fileService from "../../../../services/file.service";
import { toast } from "react-hot-toast";

const Uploader = ({
  isMulti = false,
  setUploadedFiles,
  uploadedFiles = [],
  uploadedFile = undefined,
}) => {
  let dragCounter = 0;

  const inpRef = useRef();
  const dropZoneRef = useRef();

  const [dragging, setDragging] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const uploadFile = async (filesData) => {
    try {
      setSubmitted(true);
      const response = await fileService.uploadFile([...filesData]);

      if (isMulti) {
        setUploadedFiles([...uploadedFiles, ...response.data]);
      } else {
        setUploadedFiles(response.data[0]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitted(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-plusplus
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-plusplus
    dragCounter--;
    if (dragCounter > 0) return;
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      uploadFile(e.dataTransfer.files);

      // e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  const handleChange = (e) => {
    uploadFile(e.target.files);
  };

  return (
    <>
      <div className="relative my-8 inline-block w-full transform overflow-hidden rounded-2xl align-middle">
        <div>
          <input
            ref={inpRef}
            type="file"
            name="files"
            className="hidden"
            id="files"
            multiple
            onChange={handleChange}
          />
          <div
            ref={dropZoneRef}
            onDragEnter={handleDragIn}
            onDragExit={handleDragOut}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            className={`m-h-20 flex w-full flex-col items-center justify-center rounded-md border-2 border-dashed py-10 ${
              dragging ? "border-black" : ""
            }`}
          >
            <span className="mb-5"> Drag and drop your files anywhere or </span>
            <button
              type="button"
              onClick={() => inpRef.current?.click()}
              className="rounded-md bg-gray-200 px-4 py-1 text-sm"
            >
              Choose File(s)
            </button>
          </div>

          <div className="mt-8 flex flex-col">
            <span className="mt-8">File(s):</span>
            <div className="flex flex-col">
              {submitted ? (
                <Loading />
              ) : (
                <>
                  {isMulti && uploadedFiles && uploadedFiles.length > 0 ? (
                    <>
                      {uploadedFiles.map((file) => (
                        <div
                          className="mb-2 flex items-center justify-between rounded-md bg-gray-100 px-3 py-3"
                          key={file.lastModified}
                        >
                          <div className="flex items-center truncate">
                            <img
                              src={`${process.env.REACT_APP_PICTURE_BASE_API_URL}${file.url}`}
                              className="mr-2 h-10 w-10 flex-shrink-0 overflow-hidden rounded-md"
                            />
                            <span className="truncate">{file.name}</span>
                          </div>
                          <div className="flex items-center justify-between">
                            <button
                              type="button"
                              onClick={() =>
                                setUploadedFiles((prev) =>
                                  prev.filter((x) => x.id !== file.id)
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : !isMulti && uploadedFile ? (
                    <div
                      className="mb-2 flex items-center justify-between rounded-md bg-gray-100 px-3 py-3"
                      key={uploadedFile.lastModified}
                    >
                      <div className="flex items-center truncate">
                        <img
                          src={`${process.env.REACT_APP_PICTURE_BASE_API_URL}${uploadedFile.url}`}
                          className="mr-2 h-10 w-10 flex-shrink-0 overflow-hidden rounded-md"
                        />
                        <span className="truncate">{uploadedFile.name}</span>
                      </div>
                      <div className="flex items-center justify-between">
                        <button
                          type="button"
                          onClick={() => {
                            if (!!isMulti) {
                              setUploadedFiles((prev) =>
                                prev.filter((x) => x.id !== uploadedFile.id)
                              );
                            } else setUploadedFiles(undefined);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <Empty />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Uploader;
