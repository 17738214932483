// eslint-disable-next-line
import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';

import fileService from '../../services/file.service';

const initialState = {
  files: [],
  errors: [],
  status: 'idle',
  uploadFiles: [],
  uploadErrors: [],
  uploadStatus: 'idle',
};


export const fetchFilesAsync = createAsyncThunk(
  'file/fetchFiles',
  async () => {
    const response = await fileService.fetchFiles();
    return response.data;
  }
);

export const uploadFilesAsync = createAsyncThunk(
  'file/uploadFile',
  async (data) => {
    const response = await fileService.uploadFile(data);
    return response.data;
  }
);

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    addFileToFiles: (state, action) => {
      // eslint-disable-next-line
      state.files = [...state.files, ...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilesAsync.pending, (state) => {
        // eslint-disable-next-line
        state.status = 'loading';
        // eslint-disable-next-line
        state.files = [];
      })
      .addCase(fetchFilesAsync.fulfilled, (state, action) => {
        // eslint-disable-next-line
        state.status = 'idle';
        // eslint-disable-next-line
        state.files = action.payload;
      })
      .addCase(fetchFilesAsync.rejected, (state, action) => {
        // eslint-disable-next-line
        state.status = 'failed';
        // eslint-disable-next-line
        state.errors = action.payload;
      });
    builder
      .addCase(uploadFilesAsync.pending, (state) => {
        // eslint-disable-next-line
        state.uploadStatus = 'loading';
        // eslint-disable-next-line
        state.uploadFiles = [];
      })
      .addCase(uploadFilesAsync.fulfilled, (state, action) => {
        // eslint-disable-next-line
        state.uploadStatus = 'idle';
        // eslint-disable-next-line
        state.uploadFiles = action.payload;
      })
      .addCase(uploadFilesAsync.rejected, (state, action) => {
        // eslint-disable-next-line
        state.uploadStatus = 'failed';
        // eslint-disable-next-line
        state.errors = action.payload;
      });
  },
});

export const { addFileToFiles } = fileSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectFiles = (state) => state.files;


export default fileSlice.reducer;
