import React, { useState } from "react";
import { setLogin, setToken } from "../../helpers/utils";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import authenticationServices from "../../services/authentication.services";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const Authentication = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const {
    register,
    // eslint-disable-next-line
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      identifier: "",
      password: "",
    },
  });

  const handleVerify = (e) => {
    setDisabled(false);
  };

  const refreshReCaptcha = () => {
    setDisabled(true);
  };

  const onSubmit = async (values) => {
    try {
      const { data } = await authenticationServices.login(values);

      setLogin(data.user);
      setToken(data.jwt);

      navigate("/dashboard/overview");
    } catch (error) {
      toast.error(error.message);
      console.log("error", error);
    }
  };

  return (
    <div className="flex min-h-screen min-w-full items-center justify-center bg-gray-100 p-8 dark:bg-neutral-800 dark:text-white">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full max-w-md space-y-4 rounded-lg border bg-white p-8 pt-12 dark:border-0 dark:bg-neutral-700"
      >
        <h3 className="pb-8 text-5xl font-black">Sign in</h3>
        <div className="flex flex-col space-y-1">
          <label htmlFor="">Username</label>
          <input
            type="text"
            {...register("identifier")}
            className="rounded-md border border-slate-300 bg-transparent px-3 py-3 placeholder-slate-400 shadow-sm focus:border-slate-500 focus:outline-none focus:ring-1 focus:ring-slate-500 dark:placeholder-neutral-400 dark:focus:border-neutral-500 dark:focus:ring-neutral-500"
          />
        </div>

        <div className="flex flex-col space-y-1">
          <label htmlFor="">Password</label>
          <input
            type="password"
            {...register("password")}
            className="rounded-md border border-slate-300 bg-transparent px-3 py-3 placeholder-slate-400 shadow-sm focus:border-slate-500 focus:outline-none focus:ring-1 focus:ring-slate-500 dark:placeholder-neutral-400 dark:focus:border-neutral-500 dark:focus:ring-neutral-500"
          />
        </div>

        <div className="flex flex-col space-y-1">
          <GoogleReCaptcha onVerify={handleVerify} />
        </div>

        <button
          className="!mt-8 block w-full rounded-md bg-neutral-950 p-3 text-lg text-white shadow-sm hover:bg-neutral-900 disabled:bg-opacity-70 disabled:cursor-not-allowed"
          type="submit"
          disabled={disabled}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default Authentication;
