import {
  getCookieLanguages,
  getToken,
  setRequestConfig,
  setToken,
} from "./utils";

import AxiosMockAdapter from "axios-mock-adapter";
import authenticationServices from "../services/authentication.services";
import axios from "axios";
import qs from "qs";
import request from "./request";

let failedQueue = [];

const getTextFromHttpResponse = {
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a Teapot",
  421: "Misdirected Request",
  422: "Unprocessable Entity",
  423: "Locked",
  424: "Failed Dependency",
  425: "Too Early",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  451: "Unavailable For Legal Reasons",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
};

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const instanceMock = axios.create({
  timeout: 60000,
  headers: {
    "Accept-Language": "en",
  },
});

export const instanceMockAdapter = new AxiosMockAdapter(instanceMock, {
  delayResponse: 1000,
});

const instance = axios.create({
  timeout: 60000,
  headers: {
    "Accept-Language": "fa",
  },
});

class Request {
  self;

  constructor(baseUrl, headers, axiosInstance) {
    this.self = {
      baseUrl,
      headers,
      instance: axiosInstance || instance,
    };
  }

  post = (endpoint, data, config) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "POST",
      headers: this.self.headers,
      ...config,
    });
  };

  get = (endpoint, params, config) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      params,
      method: "GET",
      headers: this.self.headers,
      ...config,
    });
  };

  put = (endpoint, data, config) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "PUT",
      headers: this.self.headers,
      ...config,
    });
  };

  patch = (endpoint, data, config) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "PATCH",
      headers: this.self.headers,
      ...config,
    });
  };

  delete = (endpoint, data, config) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "DELETE",
      headers: this.self.headers,
      ...config,
    });
  };
}

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    const { url } = config;

    const newConfig = config;

    if (!token && !url.includes("/auth/")) window.location.href = "/";

    if (token && !url.includes("/auth/")) {
      // eslint-disable-next-line
      // @ts-ignore
      newConfig.headers.Authorization = `Bearer ${token}`;

      setRequestConfig({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return newConfig;
    // Do something before request is sent
  },
  (error) => {
    // Do something with request error
    console.log(`error`, error);
    Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    response,
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (!error.response || !error.config) {
      return Promise.reject({
        errors: error,
        fingerPrint: null,
        message: "خطا در ارتباط با سرور",
      });
    } else {
      const originalConfig = error.config;
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        // try {
        //   const token = getToken();
        //   const { data } = await request
        //     .withHeaders({ "Content-Type": "application/json;utf-8" })
        //     .build()
        //     .post(`/user/oauth/token?lang=fa`, "", {
        //       // @ts-ignore
        //       _retryToken: true,
        //       _retry: true,
        //       headers: {
        //         Authorization: `Bearer ${token}`,
        //         "Content-Type": "application/x-www-form-urlencoded;utf-8",
        //       },
        //       data: qs.stringify({
        //         scope: tokens.scope,
        //         refresh_token: tokens.refresh_token || "",
        //         grant_type: "refresh_token",
        //       }),
        //     });

        //   const { access_token } = data;
        //   setToken(data);
        //   instance.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        //   // processQueue(null, data.access_token);
        //   return instance(originalConfig);
        // } catch (_error) {
        //   console.log("_error", _error);
        //   if (_error.response && _error.response.data) {
        //     if (_error.response.status === 401) {
        //       authenticationServices.logout();
        //       window.location.href = "/";
        //       return Promise.reject(_error);
        //     } else return Promise.reject(_error.response.data);
        //   }

        //   return Promise.reject(_error);
        // }

        return Promise.reject(error);
      } else if (error.response.status === 401 && originalConfig._retryToken) {
        authenticationServices.logout();
        window.location.href = "/";
        return Promise.reject(error);
      }

      if (
        error.response.status !== 401 ||
        originalConfig.url?.startsWith(
          `${
            window._env_.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL
          }/public`
        )
      ) {
        if (error.response.data) {
          const { msg, message } = error.response.data;
          if (message) {
            return Promise.reject({
              status: error.response.status,
              errors: null,
              fingerPrint: null,
              message,
            });
          } else if (msg) {
            return Promise.reject({
              status: error.response.status,
              errors: null,
              fingerPrint: null,
              message: msg,
            });
          } else {
            return Promise.reject({
              status: error.response.status,
              errors: null,
              fingerPrint: null,
              message:
                getTextFromHttpResponse[error.response.status] ||
                getTextFromHttpResponse[503],
            });
          }
        } else {
          // eslint-disable-next-line
          return Promise.reject({
            status: error.status,
            errors: null,
            fingerPrint: null,
            message: error.response.statusText,
          });
        }
      }
    }
  }
);

export default Request;
