import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';

import { InputTypes } from '../../CRUD';
import MediaSelect from './MediaSelect';

const FileSelect = ({ show, onChange }) => {
  const [modal, setModal] = useState(show || false);
  const [url, setUrl] = useState("");

  // const openModal = (e: React.MouseEvent<HTMLElement>) => {
  const openModal = () => {
    setModal(!modal)
  }

  const handleCancel = () => {
    setModal(false);
  };

  const handleOk = () => {
    setModal(false);
  };

  // eslint-disable-next-line
  const handleChange = (e) => {
    if (e.target.value) {
      setUrl(e.target.value);
    }
  };

  useEffect(() => {
    if (!modal) {
      onChange(url);
    }
  }, [modal])

  return (
    <>
      {/* eslint-disable-next-line */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 svg-icon"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={openModal}
        role="img"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>


      {/* <div className="rdw-list-wrapper"> */}
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={handleCancel}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="all"
              enterTo="all"
              leave="ease-in duration-200"
              leaveFrom="all"
              leaveTo="all"
            >
              <Dialog.Overlay className="fixed inset-0 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-90" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-block w-full max-w-lg p-10 my-8 overflow-hidden align-middle transition-all transform bg-white shadow-2xl rounded-2xl">
                <Dialog.Title as="h3" className="font-bold leading-6 text-gray-900 my-8">
                  Add new File
                </Dialog.Title>

                <button
                  type="button"
                  className="absolute top-3 left-4 text-gray-300 cursor-pointer"
                  onClick={handleCancel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>



                <div>
                  <MediaSelect
                    field={{
                      name: 'file',
                      id: 'file',
                      title: null,
                      type: InputTypes.MEDIA,
                      value: '',
                    }}
                    value=""
                    handleClick={handleChange}
                  />
                </div>



                <div className="flex flex-col justify-center items-center">
                  <div className="mb-6 flex justify-center space-x-2 rtl:space-x-reverse">
                    <button
                      type="button"

                      onClick={handleOk}
                      className="flex items-center justify-center bg-gray-900 shadow-xl rounded px-12 text-sm py-2 text-white"
                    >
                      <span>Finish</span>
                    </button>

                    <button
                      type="button"
                      onClick={handleCancel}
                      className="flex items-center justify-center rounded px-12 text-sm py-2 bg-white border border-gray-900 text-gray-900"
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      {/* </div> */}
    </>
  );
}




export default FileSelect