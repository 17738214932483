import 'react-mde/lib/styles/css/react-mde-all.css';

import * as Showdown from 'showdown';

import React, { useEffect, useState } from 'react';
import ReactMde, {
  MarkdownUtil,
  getDefaultToolbarCommands,
} from 'react-mde';

import FileSelect from './FileSelect';

let renderCount = 0;

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

function setHeader(
  initialState,
  api,
  prefix,
) {
  // Adjust the selection to encompass the whole word if the caret is inside one
  const newSelectionRange = MarkdownUtil.selectWord({
    text: initialState.text,
    selection: initialState.selection,
  });

  const state1 = api.setSelectionRange(newSelectionRange);
  // Add the prefix to the selection
  const state2 = api.replaceSelection(`${prefix} ${state1.selectedText}`);
  // Adjust the selection to not contain the prefix
  api.setSelectionRange({
    start: state2.selection.end,
    end: state2.selection.end,
  });
}

export const headerOneCommand = {
  icon: () => (
    <span role="img" aria-label="Add Header One">
      H1
    </span>
  ),
  execute: ({ initialState, textApi }) => {
    setHeader(initialState, textApi, '#');
  },
};

export const headerTwoCommand = {
  icon: () => (
    <span role="img" aria-label="Add Header Two">
      H2
    </span>
  ),
  execute: ({ initialState, textApi }) => {
    setHeader(initialState, textApi, '##');
  },
};

const customSelectFileCommand = function () {

  // const [imgURL, setImgURL] = useState("");
  // const [opt, setOpt] = useState();

  // useEffect(() => {
  //   if (imgURL.length) {
  //     opt?.textApi.replaceSelection(`![${imgURL}](${imgURL})`);
  //   }
  // }, [imgURL])

  return {
    name: 'select-file-command',
    icon: () => (
      <FileSelect
        onChange={url => {
          // setImgURL(url);
        }}
      />
    ),
    buttonProps: {
      'aria-label': 'Add Image'
    },
    execute: opts => {
      // setOpt(opts)
    },
  };
};


const ContentEditor = ({ field, value: valueProps, onChange: onChangeProps }) => {
  const toolbars = getDefaultToolbarCommands();
  const [value, setValue] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState(
    'write',
  );

  renderCount += 1;

  useEffect(() => {
    setValue(valueProps);
  }, [valueProps]);


  useEffect(() => {
    onChangeProps({ target: { value, name: field.name } });
  }, [value]);


  const handleChange = (e) => {
    setValue(e);
  }

  return (
    <>
      <input type="hidden" value={renderCount} />
      <ReactMde
        commands={{
          'select-file': customSelectFileCommand(),
          H1: headerOneCommand,
          H2: headerTwoCommand,
        }}
        toolbarCommands={[...toolbars, ['select-file', 'H1', 'H2']]}
        value={value}
        onChange={handleChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={markdown =>
          Promise.resolve(converter.makeHtml(markdown))
        }
      />
    </>
  );
}

export default ContentEditor;
