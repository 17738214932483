import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line
// import counterReducer from '../features/counter/counterSlice';
// eslint-disable-next-line
import filesReducer from '../components/MediaSelect/filesSlice';

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    files: filesReducer,
  },
});
