import React, { useEffect, useState } from "react";

import request from "../../../../helpers/request";
import toast from "react-hot-toast";

const Content = () => {
  // eslint-disable-next-line
  const [count, setCount] = useState(0);

  useEffect(() => {
    request
      .withHeaders({ "Content-Type": "application/json" })
      .build()
      .get(`/api/events`)
      .then((response) => {
        setCount(response.data.meta.pagination.total || 0);
      })
      .catch((error) =>
        toast.custom(
          <div className="flex max-w-sm items-center space-x-5 rounded-lg bg-white p-4 shadow-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 flex-shrink-0 text-red-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{error.message}</span>
          </div>
        )
      );
  }, []);

  return (
    <div className="flex items-center justify-between rounded-md bg-white p-4 dark:bg-neutral-700">
      <div>
        <h6 className="dark:text-primary-light text-xs font-medium uppercase leading-none tracking-wider text-gray-500">
          Content
        </h6>
        <span className="text-xl font-semibold">{count}</span>
        {/* <span className="ml-2 inline-block rounded-md bg-green-100 px-2 py-px text-xs text-green-500">
          +2.6%
        </span> */}
      </div>
      <div>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-gray-300 dark:text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default Content;
