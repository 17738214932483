import React, { useEffect, useState } from "react";

import Map from ".";
import { MapProvider } from "../../../../hooks/mapHook";

const MapWrapper = ({ location, setLocation, viewport={} }) => {
  

  return (
    <MapProvider>
      <Map setLocation={setLocation} location={location} viewport={viewport}  />
    </MapProvider>
  );
};

export default MapWrapper;
