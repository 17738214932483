import "maplibre-gl/dist/maplibre-gl.css";
import "@maptiler/geocoding-control/style.css";

import React, { useEffect, useRef, useState } from "react";

import { GeocodingControl } from "@maptiler/geocoding-control/react";
import { createMapLibreGlMapController } from "@maptiler/geocoding-control/maplibregl-controller";
import maplibregl from "maplibre-gl";

let marker = false;

const Map = ({ location, setLocation, viewport }) => {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  
  if (location) {
    if (marker) marker.remove();
    const el = document.createElement("div");
    marker = new maplibregl.Marker({
      className: "marker",
      element: el,
    })
      .setLngLat([location.lng, location.lat])
      .addTo(mapRef.current);
  }

  const [mapController, setMapController] = useState();
  const [mapViewport, setMapViewport] = useState({
    longitude: 35.2332,
    latitude: 31.9522,
    zoom: 8,
  });

  useEffect(() => {
    if (mapRef.current) return; // stops map from intializing more than once

    maplibregl.setRTLTextPlugin(
      "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
      null,
      true // Lazy load the plugin
    );

    mapRef.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/58fe7c10-f667-4fae-8a6c-a1224ed913d5/style.json?key=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      center: [mapViewport.longitude, mapViewport.latitude],
      zoom: mapViewport.zoom,
    });

    mapRef.current.on("dblclick", (x) => {
      if (x.originalEvent.which === 1) handleOpenCreateEventModal(x);
    });

    mapRef.current.addControl(new maplibregl.NavigationControl(), "top-right");

    setMapController(createMapLibreGlMapController(mapRef.current, maplibregl));

    mapRef.current.doubleClickZoom.disable();
  }, [mapViewport]);

  useEffect(() => {
    const newMV = { ...mapViewport, ...viewport };
    setMapViewport(newMV);
  }, [viewport]);

  const handleOpenCreateEventModal = (x) => {
    setLocation(x.lngLat);
  };

  return (
    <>
      <div className="map-wrap relative">
        <div className="geocoding absolute left-2 top-2">
          <GeocodingControl
            apiKey={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapController={mapController}
            class=""
          />
        </div>
        <div ref={mapContainer} className="map" style={{ height: "500px" }} />
      </div>
    </>
  );
};

export default Map;
