import Contents from "../pages/dashboard/contents/Contents";
import DraftContent from "../pages/dashboard/contents/Draft";
import EditContent from "../pages/dashboard/contents/Edit";
import Overview from "../pages/dashboard/overview/Overview";

const routes = [
  {
    keyIndex: "1",
    icon: (active) => (
      <div className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-8 w-8 ${active ? "text-black dark:text-white" : "text-gray-500 dark:text-neutral-500"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={1.5}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      </div>
    ),
    path: "/dashboard/overview",
    inMenu: true,
    title: "Dashboard",
    roles: [],
    privileges: [],
    element: <Overview />,
    // disabled: true,
  },
  {
    keyIndex: "2",
    icon: (active) => (
      <div className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-8 w-8 ${active ? "text-black dark:text-white" : "text-gray-500 dark:text-neutral-500"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={1.5}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
          />
        </svg>
      </div>
    ),
    path: "/dashboard/contents",
    inMenu: true,
    // exact: false,
    title: "Contents",
    roles: [],
    privileges: [],
    element: <Contents />,
    // disabled: true,
  },
  {
    keyIndex: "2",
    path: "/dashboard/contents/draft",
    inMenu: false,
    title: "Create Content",
    roles: [],
    privileges: [],
    element: <DraftContent />,
    // disabled: true,
  },
  {
    keyIndex: "2",
    path: "/dashboard/contents/:contentId",
    inMenu: false,
    // exact: false,
    title: "Edit Content",
    roles: [],
    privileges: [],
    element: <EditContent />,
    // disabled: true,
  },
];

export default routes;
