import {NavLink, useLocation} from 'react-router-dom';
import React, {ReactNode} from 'react';

import styled from 'styled-components';

const DisableItem = styled.span``;
const Item = styled(NavLink)``;

const PageItem = props => {
  const {pathname} = useLocation();

  const {disabled, currentPage, value, label, shadow = true} = props;

  return disabled ? (
    <DisableItem
      className={`inline-flex justify-center rounded-md ${shadow && 'shadow'} ${
        currentPage === value
          ? 'bg-black hover:bg-black text-white dark:bg-neutral-600 dark:text-white'
          : 'bg-gray-100 dark:border-gray-200 text-black dark:bg-neutral-600 dark:text-white'
      }  py-1 px-2 min-w-7 cursor-not-allowed select-none transition-all`}
    >
      {label}
    </DisableItem>
  ) : (
    <Item
      to={`${pathname}?page=${value}`}
      className={`inline-flex justify-center rounded-md transition-all ${shadow && 'shadow'} ${
        currentPage === value
          ? 'bg-black border-transparent text-white dark:border-black dark:bg-neutral-600 dark:text-white dark:hover:text-white dark:hover:border-transparent cursor-default'
          : 'bg-white dark:border-gray-500 hover:bg-gray-100 dark:bg-black dark:hover:bg-black/50 text-black dark:text-white cursor-pointer'
      }  py-1 px-2 min-w-7 cursor-not-allowed select-none`}
    >
      {label}
    </Item>
  );
};

export default PageItem;
