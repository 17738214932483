import Content from "./components/Content";
import DailyView from "./components/DailyView";
import DailyAllView from "./components/DailyAllView";
import PopularEvent from "./components/PopularEvent";
import React from "react";
import View from "./components/View";

const Overview = () => (
  <div className="flex flex-col gap-8 pb-8">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-4">
      <Content />
      <View />
      <PopularEvent />
    </div>

    <div className="grid grid-cols-1 gap-8">
      <DailyView />
    </div>
    <div className="grid grid-cols-1 gap-8">
      <DailyAllView />
    </div>
  </div>
);

export default Overview;
