import PageItem from "./PageItem";
import React from "react";
import paginate from "./paginate";

const Pagination = (props) => {
  const {
    totalItems: totalitems,
    currentPage: currentpage,
    pageSize: pagesize,
    maxPages: maxpages,
  } = props;

  // eslint-disable-next-line
  const {
    // eslint-disable-next-line
    totalItems,
    // eslint-disable-next-line
    currentPage,
    // eslint-disable-next-line
    pageSize,
    // eslint-disable-next-line
    totalPages,
    // eslint-disable-next-line
    startPage,
    // eslint-disable-next-line
    endPage,
    // eslint-disable-next-line
    startIndex,
    // eslint-disable-next-line
    endIndex,
    pages,
  } = paginate(totalitems, currentpage, pagesize, maxpages);

  return (
    <div
      className="mt-6 grid gap-1"
      style={{
        gridTemplateColumns: "repeat(auto-fill, minmax(1.75rem, 1fr))",
      }}
    >
      <PageItem
        currentPage={currentpage}
        value={currentpage - 1}
        shadow={false}
        label={
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
        }
        disabled={currentpage === 1}
      />

      {pages && pages.length && currentpage > 2 ? (
        <span className="inline-flex select-none items-center justify-center">
          ...
        </span>
      ) : (
        ""
      )}

      {pages.map((key) => (
        <PageItem key={key} currentPage={currentpage} value={key} label={key} />
      ))}
      {/* {currentpage + pages.length / 2} */}
      {pages && pages.length && Math.round(currentpage + (pages.length - 1) / 2) < totalPages ? (
        <span className="inline-flex select-none items-center justify-center">
          ...
        </span>
      ) : (
        ""
      )}

      <PageItem
        currentPage={currentpage}
        value={currentpage + 1}
        shadow={false}
        label={
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        }
        disabled={currentpage === Math.ceil(totalItems / pagesize)}
      />
    </div>
  );
};

export default Pagination;
