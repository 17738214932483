import {
  NavLink,
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useRef, useState } from "react";
import { getLogin, isLogin } from "../../../helpers/utils";

import Sticky from "react-stickynode";
import SwitchTheme from "./components/SwitchTheme";
import UserArea from "./components/UserArea";
// @ts-ignore
import colors from "tailwindcss/colors";
import routes from "../../../app/privateRoutes";
import styled from "styled-components";
import { useOutsideSidebar } from "../../../hooks/useOutsideSidebar";

const ScrollNavbar = styled.div`
  height: 100vh;
  max-height: calc(100vh - 6rem);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuItem = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
`;

const PrivateLayout = () => {
  const userProfile = getLogin();

  const [collapsed, setCollapsed] = useState(true);
  // eslint-disable-next-line
  const [collapsible, setCollapsible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const wrapperRef = useRef(null);

  function toggle() {
    setCollapsed(!collapsed);
  }

  function closeMenu() {
    if (collapsible) setCollapsed(true);
  }

  useOutsideSidebar(wrapperRef, closeMenu);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="flex min-h-screen flex-col bg-gray-100 dark:bg-neutral-800 dark:text-white">
        <div className="fixed top-0 z-30 w-full bg-white shadow dark:bg-neutral-800 dark:shadow-neutral-900">
          <div className="flex items-center justify-between px-6 lg:px-8">
            <div className="flex h-20 w-full items-center justify-between space-x-5 rtl:space-x-reverse">
              <div className="flex flex-shrink-0 flex-grow items-center space-x-6">
                <button
                  type="button"
                  onClick={toggle}
                  className="rounded-full px-2 py-2 text-gray-500 hover:text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h7"
                    />
                  </svg>
                </button>

                {/* <form
                  className="flex items-center rounded-full bg-gray-100 px-4 py-2 pr-3 dark:bg-zinc-900"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="text"
                    name=""
                    placeholder="Search..."
                    className="bg-transparent w-full max-w-md text-sm text-gray-500 placeholder:text-gray-400 focus:outline-none dark:text-neutral-500 dark:placeholder:text-neutral-400"
                  />
                  <button type="submit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-400 dark:text-neutral-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </button>
                </form> */}

                <span className="text-2xl font-black">Palespin Admin</span>
              </div>

              <div className="flex flex-shrink-0 flex-grow items-center justify-end space-x-6">
                <SwitchTheme />
                <UserArea />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[80px] flex min-h-screen justify-stretch">
          <div
            ref={wrapperRef}
            className={`fixed z-20 h-screen
                w-32 overflow-hidden bg-white shadow transition-all duration-300 ease-in-out dark:bg-neutral-800 dark:text-white dark:shadow-lg dark:shadow-neutral-900
                ${collapsed && collapsible ? " -left-32 lg:left-0" : " left-0"}
                `}
          >
            <ScrollNavbar className="relative z-30 overflow-hidden overflow-y-auto border-0 pb-5 pt-8">
              {routes
                .filter((route) => {
                  // console.log(first)
                  return route.inMenu === true;
                })
                .map(
                  (route) =>
                    route.inMenu && (
                      <MenuItem
                        className="menu-item relative mb-3 px-3 py-3"
                        key={route.keyIndex}
                      >
                        <NavLink
                          to={route.path}
                          className="flex flex-col items-center justify-center space-y-3 rtl:space-x-reverse"
                        >
                          {route.icon &&
                            route.icon(location.pathname === route.path)}
                          <span>{route.title}</span>
                        </NavLink>
                      </MenuItem>
                    )
                )}
              <MenuItem className="menu-item px-3 py-3">
                <NavLink
                  exact
                  to="/"
                  onClick={() => {
                    localStorage.removeItem("isLogin");
                    navigate("/");
                  }}
                  className="flex flex-col items-center justify-center space-y-3 rtl:space-x-reverse"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 text-gray-500 dark:text-neutral-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  <span>Exit</span>
                </NavLink>
              </MenuItem>
            </ScrollNavbar>
          </div>

          <div className="flex min-h-full w-full flex-grow flex-col overflow-hidden px-6 pt-8 lg:ml-32 lg:px-12">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateLayout;

// @ts-ignore
export const PrivateRoute = ({ component: Component, ...rest }) => (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page
  <Route
    {...rest}
    render={(props) =>
      isLogin() ? <Component {...props} /> : <Navigate to="/auth/login" />
    }
  />
);
