import request from "../helpers/request";

function fetchFiles() {
  return request

    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/upload/files`);
}

function uploadFile(data) {
  const formData = new FormData();
  data.forEach((file) => {
    formData.append("files", file);
  });

  return request
    .withHeaders({ "Content-Type": "multipart/form-data" })
    .build()
    .post(`/api/upload`, formData);
}

export default {
  fetchFiles,
  uploadFile,
};
