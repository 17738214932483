import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InputField from "../../../../components/InputField";
import { InputTypes } from "../../../../components/CRUD";
import MapWrapper from "../Map/MapWrapper";
import Uploader from "../MediaSelectSelf/Uploder";
import request from "../../../../helpers/request";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";

const fields = (setMonth) => {
  return [
    {
      title: "Title",
      name: "title",
      id: "title",
      type: InputTypes.TEXT,
      value: "",
    },
    {
      title: "Content",
      name: "content",
      id: "content",
      type: InputTypes.TEXTAREA,
      value: "",
    },
    {
      title: "Type",
      name: "type",
      id: "type",
      type: InputTypes.SELECT,
      options: [
        { label: "Default", value: "default" },
        { label: "Breaking  News", value: "breaking_news" },
      ],
      value: null,
    },

    {
      title: "Event At",
      name: "eventAt",
      id: "eventAt",
      className: "mt-8",
      type: InputTypes.DATE,
      value: null,
      props: {
        onMonthChange: (month) => {
          const v = dayjs(month).utcOffset(dayjs().utcOffset());
          setMonth(v.format("YYYY-MM-DD"));
        },
      },
    },
  ];
};

const EditContentPage = () => {
  const navigate = useNavigate();
  const { contentId } = useParams();

  const [location, setLocation] = useState(undefined);
  const [banner, setBanner] = useState(undefined);
  const [month, setMonth] = useState(undefined);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(
    fields(month, setMonth).reduce((result, item) => {
      // eslint-disable-next-line no-param-reassign
      result[item.name] = item.value;
      return result;
    }, {})
  );

  const fetchData = () => {
    setTimeout(async () => {
      setLoading(true);

      request
        .withHeaders({ "Content-Type": "application/json" })
        .build()
        .get(`/api/events/${contentId}`, {
          populate: "*",
        })
        .then(({ data }) => {
          const { id, attributes } = data.data;

          const v = {
            id,
          };

          // eslint-disable-next-line no-restricted-syntax
          for (const [key] of Object.entries(attributes)) {
            if (key === "banner") {
              const tmp = attributes[key]?.data;
              setBanner({ id: tmp.id, ...tmp.attributes });
            } else if (key === "media") {
              setMedia(
                attributes[key]?.data.map((m) => ({
                  id: m.id,
                  ...m.attributes,
                }))
              );
            } else if (key === "location") {
              setLocation({
                lat: attributes[key].lat,
                lng: attributes[key].lng,
              });
            } else if (key === "eventAt") {
              v[key] = new Date(attributes[key] || (new Date()).getTime());
              setMonth(
                new Date(v[key].getFullYear(), Number(v[key].getMonth()))
              );
            } else {
              v[key] = attributes[key];
            }
          }

          setValues({
            ...values,
            ...v,
          });
        })
        .finally(() => setLoading(false));
    }, 1000);
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const validate = () => {
    if (!values.title) {
      throw new Error("Title is required");
    }
    if (!values.content) {
      throw new Error("Content is required");
    }
    if (!values.type) {
      throw new Error("Type is required");
    }
    if (!values.eventAt) {
      throw new Error("Event At is required");
    }
    if (!banner) {
      throw new Error("Banner is required");
    }
    const { mime } = banner;
    if (banner && !["image/png", "image/gif", "image/jpeg"].includes(mime)) {
      throw new Error("Banner is must be image");
    }

    if (!media || media.length === 0) {
      throw new Error("Media is required");
    }
    if (!location) {
      throw new Error("Location is required");
    }
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await validate();
      await request
        .withHeaders({ "Content-Type": "application/json" })
        .build()
        .put(
          `/api/events/${contentId}`,
          JSON.stringify({
            data: {
              ...values,
              location,
              banner,
              media,
              publishedAt: new Date(),
            },
          })
        );
      navigate("/dashboard/contents");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDraft = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await validate();
      await request
        .withHeaders({ "Content-Type": "application/json" })
        .build()
        .put(
          `/api/events/${contentId}`,
          JSON.stringify({
            data: { ...values, banner, media, location, publishedAt: null },
          })
        );
      navigate("/dashboard/contents");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if ("eventAt" === e.target.name) {
      const v = dayjs(e.target.value).utcOffset(dayjs().utcOffset());
      setValues({
        ...values,
        [e.target.name]: new Date(v.format("YYYY-MM-DD")),
      });
    } else setValues({ ...values, [e.target.name]: e.target.value });
  };

  const renderFields = useCallback(() => {
    return (
      <>
        {fields
          ? fields((v) => setMonth(v)).map((field, i) => (
              <InputField
                field={{ ...field, props: { ...field.props, month } }}
                key={i}
                value={values[field.name]}
                handleChange={handleChange}
              />
            ))
          : ""}
      </>
    );
  }, [values, loading, month]);

  return (
    <>
      <div className="mb-5 flex items-center justify-between">
        <h2 className="text-4xl font-black">Update Content</h2>

        <div className="flex items-center space-x-2 ">
          <button
            type="button"
            className="rounded bg-black px-8 py-2 text-white"
            disabled={loading}
            onClick={handlePublish}
          >
            Publish
          </button>

          <button
            type="button"
            className="rounded bg-black/50 px-8 py-2 text-white"
            disabled={loading}
            onClick={handleDraft}
          >
            Suspend
          </button>
        </div>
      </div>

      {/* <div className="flex flex-col space-x-0 xl:flex-row xl:space-x-8"> */}
      <div className="flex flex-col">
        {/* <div className="order-2 mb-8 flex-grow self-start rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white xl:order-1"> */}
        <div className="mb-8 w-full flex-grow rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
          <div className="space-y-6">{renderFields()}</div>
        </div>
        <div className="mb-8 w-full space-y-8 self-stretch">
          <div className="flex flex-col space-y-2 rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
            <span className="font-semibold text-gray-600 dark:text-neutral-500">
              Banner
            </span>
            <div className="">
              <Uploader
                uploadedFile={banner}
                setUploadedFiles={(file) => setBanner(file)}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
            <span className="font-semibold text-gray-600 dark:text-neutral-500">
              Media
            </span>
            <Uploader
              isMulti={true}
              uploadedFiles={media}
              setUploadedFiles={(files) => setMedia(files)}
            />
          </div>
          <div className="flex flex-col space-y-2 rounded bg-white p-5 shadow-xl dark:bg-neutral-700 dark:text-white">
            <span className="font-semibold text-gray-600 dark:text-neutral-500">
              Map
            </span>
            <MapWrapper
              setLocation={setLocation}
              location={location}
              viewport={
                location
                  ? { longitude: location.lng, latitude: location.lat }
                  : {}
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditContentPage;
