import AuthenticationLayout from "../components/Layout/Public";
import PrivateLayout from "../components/Layout/Private";
import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import authRoutes from "./authRoutes";
import privateRoutes from "./privateRoutes";
import { useRoutes } from "react-router-dom";

function App() {
  const routes = useRoutes([
    {
      path: "/",
      element: <AuthenticationLayout />,
      children: [...authRoutes],
    },
    {
      path: "/dashboard",
      element: <PrivateLayout />,
      children: [...privateRoutes],
    },
  ]);

  return (
    <Suspense fallback={"Loading"}>
      <Toaster />
      <div className="min-h-screen">{routes}</div>
    </Suspense>
  );
}

export default App;
