import { CRUD, ShowTypes } from "../../../components/CRUD";

import React from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const columns = [
  // {
  //   name: '',
  //   key: 'id',
  //   render: text => <input type="checkbox" />,
  // },

  {
    name: "",
    key: "banner",
    className:
      "!p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase w-32 max-w-[8rem]",
    render: (value) => {
      return (
        <>
          {value?.data ? (
            <div className="flex flex-shrink-0 items-center justify-start overflow-hidden rounded-md">
              {value?.data != null &&
                // eslint-disable-next-line
                value?.data.hasOwnProperty("attributes") &&
                // eslint-disable-next-line
                value?.data.attributes?.hasOwnProperty("formats") &&
                // eslint-disable-next-line
                value?.data.attributes?.formats?.hasOwnProperty("thumbnail") &&
                // eslint-disable-next-line
                value?.data.attributes?.formats.thumbnail?.hasOwnProperty(
                  "url"
                ) && (
                  <img
                    className="aspect-video w-full flex-shrink-0 transform object-cover transition-all hover:scale-150"
                    // eslint-disable-next-line react/destructuring-assignment
                    key={value.data.id}
                    // eslint-disable-next-line react/destructuring-assignment
                    alt={value.data.attributes.name}
                    src={
                      process.env.REACT_APP_PICTURE_BASE_API_URL +
                      // eslint-disable-next-line react/destructuring-assignment
                      value.data.attributes.formats.thumbnail.url
                    }
                  />
                )}
            </div>
          ) : (
            <div className="flex flex-shrink-0 items-center justify-start overflow-hidden rounded-md">
              <div className="aspect-video w-full flex-shrink-0 transform rounded-md border object-cover transition-all hover:scale-150"></div>
            </div>
          )}
        </>
      );
    },
  },
  {
    name: "Title",
    key: "title",
    render: (value, record) => (
      <div className="max-w-[200px] truncate">
        <a
          href={`${process.env.REACT_APP_CLIENT_BASE_URL}/news/${
            record.id
          }/${record.title.replaceAll(/\s/gm, "_")}`}
          alt={record.title}
          target="_blank"
          className="max-w-[200px] truncate text-sm text-gray-900 dark:text-gray-300"
        >
          {record.title}
        </a>
      </div>
    ),
  },
  // {
  //   name: "Media",
  //   key: "media",
  //   className:
  //     "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
  //   render: (value) => {
  //     return (
  //       <>
  //         {value.data && value.data.length > 0 ? (
  //           <div className="flex items-center justify-start -space-x-2">
  //             {[...value.data]
  //               .filter((item) => item.attributes.mime.startsWith("image"))
  //               .map((item) => (
  //                 <>
  //                   {item != null &&
  //                     // eslint-disable-next-line
  //                     item.hasOwnProperty("attributes") &&
  //                     // eslint-disable-next-line
  //                     item.attributes.hasOwnProperty("formats") &&
  //                     // eslint-disable-next-line
  //                     item.attributes.formats.hasOwnProperty("thumbnail") &&
  //                     // eslint-disable-next-line
  //                     item.attributes.formats.thumbnail.hasOwnProperty(
  //                       "url"
  //                     ) && (
  //                       <img
  //                         className="h-6 w-6 transform rounded-full border border-gray-200 transition-all hover:scale-150 hover:z-10"
  //                         // eslint-disable-next-line react/destructuring-assignment
  //                         key={item.id}
  //                         // eslint-disable-next-line react/destructuring-assignment
  //                         alt={item.attributes.name}
  //                         src={
  //                           process.env.REACT_APP_PICTURE_BASE_API_URL +
  //                           // eslint-disable-next-line react/destructuring-assignment
  //                           item.attributes.formats.thumbnail.url
  //                         }
  //                       />
  //                     )}
  //                 </>
  //               ))}
  //           </div>
  //         ) : (
  //           <>-</>
  //         )}
  //       </>
  //     );
  //   },
  // },
  {
    name: "Status",
    key: "publishedAt",
    render: (value) => (
      <div className="text-sm text-gray-900 dark:text-gray-300">
        <span className="rounded-full bg-gray-200 px-2 py-1 text-xs font-semibold capitalize leading-5 text-gray-500 dark:bg-neutral-600 dark:text-neutral-300">
          {value ? "Published" : "Draft"}
        </span>
      </div>
    ),
  },
  // {
  //   name: "Created at",
  //   key: "created_at",
  //   render: (value) => <>{dayjs(value).format("DD MMMM YYYY HH:mm:ss")}</>,
  // },
  // {
  //   name: "Published at",
  //   key: "published_at",
  //   render: (value) => <>{dayjs(value).format("DD MMMM YYYY HH:mm:ss")}</>,
  // },
  {
    name: "Updated at",
    key: "updatedAt",
    render: (value, record) => (
      <>{dayjs(value || record.createdAt).format("DD MMMM YYYY HH:mm:ss")}</>
    ),
  },
  {
    name: "Event at",
    key: "eventAt",
    render: (value) => <>{value ? dayjs(value).format("DD MMMM YYYY") : "-"}</>,
  },
  {
    name: "Type",
    key: "type",
    render: (value, record) => (
      <>
        <div className="text-sm text-gray-900 dark:text-gray-300">
          {value ? (
            <span className="rounded-full bg-gray-200 px-2 py-1 text-xs font-semibold capitalize leading-5 text-gray-500 dark:bg-neutral-600 dark:text-neutral-300">
              {value.replaceAll(/\_/gm, " ")}
            </span>
          ) : (
            "-"
          )}
        </div>
      </>
    ),
  },
];

function Contents() {
  return (
    <>
      <CRUD
        readOptions={{
          columns,
          api: `/api/events`,
        }}
        createOptions={{
          enable: true,
          button: {
            text: "Create Content",
            className:
              "inline-flex items-center justify-center focus:outline-none text-white px-6 py-2 space-x-1 bg-black rounded-md shadow hover:bg-black",
          },
          showType: ShowTypes.PAGE,
          pageAddress: `/dashboard/contents/draft`,
        }}
        deleteOptions={{
          enable: true,
          showType: ShowTypes.MODAL,
          modal: {
            title: "Delete %s",
            fields: [],
            okText: "Delete",
            replaceNameKey: "title",
          },
          api: `/api/events/:id`,
        }}
        updateOptions={{
          enable: true,
          showType: ShowTypes.PAGE,
          pageAddress: `/dashboard/contents/:id`,
        }}
      />
    </>
  );
}

export default Contents;
