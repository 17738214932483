import Request, { instanceMock } from "./requestUtil";

class RequestBuilder {
  self;

  constructor() {
    this.self = {
      baseUrl: "",
      headers: {},
    };
    this.detectBaseUrlBaseOnEnv();
  }

  detectBaseUrlBaseOnEnv() {
    console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT);
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "local":
        this.self.baseUrl = "";
        return this;
      case "development":
        // @ts-ignore
        this.self.baseUrl = `${
          window._env_.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL
        }`;
        return this;
      case "production":
        // @ts-ignore
        this.self.baseUrl = `${
          window._env_.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL
        }`;
        return this;
      default:
        return this;
    }
  }

  forFileManagerBaseUrl() {
    this.self.baseUrl =
      window.REACT_APP_FILE_MANAGER_BASE_BASE_URL ||
      process.env.REACT_APP_FILE_MANAGER_BASE_BASE_URL ||
      "";
    return this;
  }

  withHeaders(headers) {
    this.self.headers = { ...this.self.headers, ...headers };
    return this;
  }

  build(option) {
    return new Request(
      option?.mock ? "" : this.self.baseUrl,
      this.self.headers,
      option?.mock ? instanceMock : undefined
    );
  }
}

const request = new RequestBuilder();

export default request;
