import "react-day-picker/dist/style.css";

import React, { memo, useEffect } from "react";

import ContentEditor from "../ContentEditor";
import { DayPicker } from "react-day-picker";
import { InputTypes } from "../CRUD";
import MediaSelect from "../MediaSelect";
import Select from "../Select";
import { v4 as uuid } from "uuid";

const colors = [
  "#ffffff",
  "#000000",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
];

const InputField = ({
  field,
  defaultValue,
  value,
  // defaultValue,
  handleChange,
}) => (
  <div className={`flex flex-col space-y-2 ${field.className || ""}`}>
    <label
      className="font-semibold text-gray-600 dark:text-neutral-500"
      htmlFor={field.id}
    >
      {field.title}
    </label>
    {(() => {
      switch (field.type) {
        case InputTypes.TEXT:
          return (
            <input
              type="text"
              name={field.name}
              id={field.id}
              value={value}
              disabled={field.readOnly}
              onChange={handleChange}
              className="mb-3 h-9 rounded border px-2 py-1"
            />
          );
        case InputTypes.NUMBER:
          return (
            <input
              type="number"
              name={field.name}
              id={field.id}
              value={value}
              onChange={handleChange}
              className="mb-3 h-9 rounded border px-2 py-1"
            />
          );
        case InputTypes.TEXTAREA:
          return (
            <textarea
              name={field.name}
              id={field.id}
              value={value}
              onChange={handleChange}
              rows={5}
              className="mb-3 rounded border px-2 py-1"
            />
          );
        case InputTypes.DATE:
          return (
            <>
              <DayPicker
                name={field.name}
                id={field.id}
                mode="single"
                selected={value}
                className="!m-0"
                styles={{
                  caption: { color: "red" },
                }}
                onSelect={(v) =>
                  handleChange({ target: { value: v, name: field.name } })
                }
                {...field.props}
                // footer={footer}
              />
            </>
          );
        case InputTypes.MEDIA:
          return (
            <>
              <MediaSelect
                multi={field.isMulti}
                field={field}
                value={value}
                handleClick={handleChange}
              />
            </>
          );
        case InputTypes.SELECT:
          return field.isMulti ? (
            <select
              name={field.name}
              id={field.id}
              disabled={field.readOnly}
              onChange={(e) => {
                handleChange({
                  target: {
                    value: e.target.value,
                    name: field.name,
                  },
                });
              }}
              defaultValue={defaultValue}
              value={
                field.options && field.options.length > 0 && value
                  ? // eslint-disable-next-line
                    field.options.find((o) => o.value == value)?.value
                  : undefined
              }
              className="mb-3 h-9 rounded border px-2 py-1"
            >
              {/* <option value="" /> */}
              {field.options && field.options.length > 0
                ? field.options.map((option) => {
                    const defaultId = uuid();
                    return (
                      <option key={defaultId} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })
                : ""}
            </select>
          ) : (
            <Select
              options={field.options}
              name={field.name}
              id={field.id}
              value={value}
              disabled={field.readOnly || false}
              onChange={handleChange}
            />
          );
        case InputTypes.CONTENT:
          return (
            <div className="bg-gray-100 p-2 dark:bg-neutral-700 dark:p-0">
              <ContentEditor
                field={field}
                value={value}
                onChange={handleChange}
              />
            </div>
          );

        default:
          return "";
      }
    })()}
  </div>
);

export default memo(InputField);
